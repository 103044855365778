@import "~antd/dist/antd.less";

:root {
  --primary-color: #1d1b84;
  --primary-color-dark: #0e0c75;
  --primary-color-success: #388e3c;
  --text-highlight: #cd000b;
  --bg-color: #e5e5e5;
}

// * { user-select: none; }
// *::selection { background: none; }
// *::-moz-selection { background: none; }

body {
  background: #e5e5e5;
  font-family: "Poppins", sans-serif;
}

header {
  z-index: 10 !important;
}
main {
  background: #e5e5e5;
}

// .App {
//   min-height: 100vh;
// }

span,
label,
p {
  font-weight: 500;
}

.pageProgress {
  height: 3px;
  width: 80%;
  background: #c27b00;
  position: absolute;
  top: 0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
}
.ant-card-body {
  width: 100%;
  height: 100%;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.logo {
  height: 36px;
  user-select: none;
  object-fit: contain;
  object-position: center;
}
.ant-btn-primary {
  color: #222;
  background-color: #edd48f;
  border-color: #edd48f;
}
.ant-menu-item-selected {
  color: #c27b00;
}
.logout {
  display: flex;
  align-items: center;
}

.profileImage {
  width: 32px;
  height: 32px;
  border-radius: 12px;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

// .mainContent {
//   min-height: 100vh;
// }

.loginFormContainer {
  width: 400px;
}

.logout {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

/* width */
// .overflow::-webkit-scrollbar {
//   width: 20px;
// }

/* Track */
// .overflow::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

/* Handle */
// .overflow::-webkit-scrollbar-thumb {
//   background: red;
//   border-radius: 10px;
// }
.ant-table-wrapper {
  overflow: auto;
}

.ant-table table {
  background-color: white;
}

.ant-anchor-ink::before {
  background-color: rgba(0, 0, 0, 0);
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: rgb(255, 240, 197);
}

.ant-descriptions-item-label {
  font-weight: 500;
}

.ant-form-item {
  margin-bottom: 16px;
}

.purchasingStats .statSpan {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.275rem;
}

.ant-btn-sm {
  font-size: 12px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #f7f7f7;
}

.ant-tabs-nav::before {
  border-bottom: none !important;
}

.fixedRightBottom {
  position: fixed;
  right: 62px;
  bottom: 62px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #ddd;
}
.stats {
  display: flex;
  height: 140px;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.stats p {
  font-size: 16px;
  margin-bottom: 0.375rem;
}
.stats img {
  width: 50%;
  object-fit: contain;
  padding: 1rem;
}

.stats .count {
  margin-left: 0.5rem;
  font-size: 14px;
}

.printOnly,
.section-to-hide,
.printOnlyHeader {
  display: none !important;
}

.statsPage .ant-tag {
  padding: 0.475rem 0.85rem;
  background-color: #ebebeb;
  border: none;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}

.section-to-print-raw {
  background-color: #fff;
  padding: 2rem;
  min-height: 100vh;
  width: 720px;
}
.ant-table-selection-column {
  display: none !important;
}
.A4 p {
  margin-bottom: 0;
}

.tagTab .ant-tag {
  margin-right: 0;
  border-radius: 0;
}

.ant-layout-sider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ant-layout-sider::-webkit-scrollbar {
  width: 0px;
  display: none;
}
.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noScrollBar::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.table .heading {
  display: none;
}

.advanceTable{
  width: 100%;
  text-align: center;
}
.advanceTable thead{
  background: #eaeaea;
}
.advanceTable td, .advanceTable th{
  border: 1px solid #eaeaea
}
.advanceTable th, .advanceTable td{
  padding: 10px 5px;
}

@media (max-width: 572px) {
  .logo {
    width: 140px;
  }
  .table .heading {
    display: block;
  }

  .table th {
    display: none;
  }

  .ant-table-container {
    border: none !important;
  }

  .table tbody tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 8px solid #e5e5e5;
  }

  .ant-picker-datetime-panel{
    flex-direction: column !important;
  }
}

.ant-menu-submenu .ant-menu-item{
  padding-left: 36px !important;
}
.ant-menu-sub.ant-menu-inline{
  background-color: #f2f2f2;
}

.sideBar *{
  font-size: 14px;
}

.dashboardImages img{
  padding: 1rem;
  width: 100%;
  background-color: #fff;
}

@media print {
  html {
    background-color: white;
  }
  body {
    background-color: white;
  }
  body * {
    visibility: hidden;
    -webkit-print-color-adjust: exact !important;
    min-height: auto !important;
    overflow-x: hidden;
  }
  main {
    background-color: white;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible !important;
  }
  .section-to-print {
    position: absolute !important;
    margin: auto;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    height: 100%;
    background-size: 80% !important;
    padding: 0.5rem !important;
    width: auto !important;
    height: auto !important;
  }
  .printComp {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .mainContent {
    min-height: auto !important;
  }
  .noPageBreak {
    page-break-after: always !important;
    page-break-before: always !important;
    page-break-inside: avoid !important;
  }
  .printOnly {
    display: flex !important;
    margin-top: 5rem !important;
  }
  .noMarginPadding {
    margin: 0 !important;
    padding: 0 !important;
  }
  .printOnlyHeader {
    display: flex !important;
  }
  .noPrint {
    display: none !important;
  }
  .noPrint * {
    display: none !important;
  }
  .smallFont * {
    font-size: 11px !important;
  }
  .courierHeader {
    font-size: 18px !important;
  }
}

@media (max-width: 572px) {
  .hidePhone {
    display: none;
  }
}

@primary-color: #c27b00;@link-color: #c27b00;@success-color:  #00a21b;@warning-color: #d97e00;@error-color: #CF3056;@font-size-base: 14px;@font-weight-base: 600;@heading-color: rgba(0, 0, 0, 1);@text-color: rgba(0, 0, 0, 1);@text-color-secondary: rgba(0, 0, 0, 0.7);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: rgba(0,0,0,.125);